import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h3>{`﷽`}</h3>
    <p>{`as-salāmu 'alaikum,`}</p>
    <p>{`Welcome to my blog where I share things I
have learned, as well as my thoughts
and ideas, on various topics such as religion,
current affairs, self help, etc.`}</p>
    <p>{`If this is your first time here, please look
over the `}<a parentName="p" {...{
        "href": "./guide"
      }}>{`guide`}</a>{` to
see how I will transliterate Arabic to English
among other things.`}</p>
    <p>{`If you want to equip yourself with tools in order
to learn more about Islām, check out `}<a parentName="p" {...{
        "href": "./resources"
      }}>{`resources`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      